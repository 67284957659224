<template>
  <div>
    <video
      style="width: 100%"
      :height="$route.name == 'Feed' ? 300 : 270"
      controlsList="nodownload"
      ref="videoPlayer"
      class="video-js vjs-big-play-centered"
    ></video>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady', this);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style>
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  /* content: "\F03E4" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/pause.svg');
  background-position: 50% 50%;
}
.video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder:before {
  /* content: "\F040A";
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/play.svg');
  background-position: 50% 50%;
}
.video-js .vjs-mute-control.vjs-vol-3 .vjs-icon-placeholder:before {
  /* content: "\F057E" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/v-3.svg');
  background-position: 50% 50%;
}
.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before {
  /* content: "\F0580" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/v-2.svg');
  background-position: 50% 50%;
}
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
  /* content: "\F057F" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/v-1.svg');
  background-position: 50% 50%;
}
.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  /* content: "\F0E08" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/v-off.svg');
  background-position: 50% 50%;
}
.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before {
  /* content: "\F0E57" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/pip.svg');
  background-position: 50% 50%;
}
.video-js.vjs-picture-in-picture .vjs-icon-placeholder:before {
  /* content: "\F0E59" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/pip-end.svg');
  background-position: 50% 50%;
}
.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  /* content: "\F0293" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/full-screen.svg');
  background-position: 50% 50%;
}
.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  /* content: "\F0294" !important;
  font: normal normal normal 18px/1 "Material Design Icons";
  top: 5px; */
  content: '' !important;
  background-image: url('./../assets/icon/end-full-screen.svg');
  background-position: 50% 50%;
}
.vjs-slider-horizontal .vjs-volume-level:before,
.video-js .vjs-play-progress:before {
  content: '|' !important;
  /* font: normal normal normal 18px/1 "Material Design Icons"; */
  top: -4px;
  right: -0.25em;
  margin-left: -5px;
}
.video-js .vjs-progress-control:hover .vjs-play-progress:before {
  top: -7px;
}
</style>
